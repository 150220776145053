<template>
    <ChatMessageControls
        text-placeholder="Write your response"
        recording-button-text="Reply now"
        :ref="controlsRef"
        :is-sending="false"
        :is-transcribing="isTranscribing"
        :show-slow-transcription="false"
        :recording-onboarding-enabled="false"
        @recording-complete="handleRecordingComplete"
        @recording-error="handleRecordingError"
        @text-send="handleSendMessage"
    />
</template>

<script setup>
import { ref, inject } from "vue";
import { transcribeRecording, encodeAudio } from "/js/transcription.js";
import ChatMessageControls from "~vue/ChatWidgets/ChatMessageControls.vue";
import { CHAT_EVENT } from "~vue/events.js";

const emit = defineEmits(["answer"]);
const controlsRef = ref(null);
const isTranscribing = ref(false);

const { $sendEvent, emitter } = inject("globalProperties");

function handleRecordingError() {}

async function handleRecordingComplete({ blob }) {
    isTranscribing.value = true;
    const encodedAudio = await encodeAudio(blob);
    const transcript = await transcribeRecording({
        encodedAudio,
        sendEvent: $sendEvent,
        onError: () => {
            if ("Sentry" in window) {
                window.Sentry.captureException(new Error("Transcription request failed"));
            }
        },
    });
    isTranscribing.value = false;

    emit("answer", transcript);
}

function handleSendMessage(message) {
    emit("answer", message.content);
    emitter.emit(CHAT_EVENT.SET_MESSAGE_TEXT, "");
}
function handleCancelTranscription() {}
</script>
