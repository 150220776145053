<template>
    <div class="flex flex-col justify-between p-10 overflow-y-auto min-h-screen h-full bg-[#FAFAFA]">
        <Transition appear mode="out-in" name="fade">
            <template v-if="flowStep === FLOW_STEP.INTRO">
                <div class="grow justify-center max-w-xl mx-auto flex flex-col gap-16">
                    <div>
                        <h1 class="text-4xl text-gray-700">Hi, I&apos;m Nadia</h1>
                        <p class="mt-4 text-lg prose">
                            I&apos;m here to help {{ reviewee_name }} gather valuable feedback to grow and thrive as a leader. Your perspective is super valuable and will make a
                            real difference. <br /><br />
                            It only takes 2 minutes, and your input will help {{ reviewee_name }} wrap up the year with meaningful insights.
                        </p>
                    </div>
                    <button
                        class="bg-valence-pink hover:bg-valence-pink-600 transition-colors ease-in-out duration-150 text-white font-bold py-3 px-8 leading-none font-medium rounded-full disabled:bg-transparent disabled:border-valence-pink disabled:border disabled:text-valence-pink"
                        type="button"
                        @click="nextFlowStep()"
                    >
                        Continue
                    </button>
                </div>
            </template>
            <template v-else-if="flowStep === FLOW_STEP.QUESTIONS">
                <Transition mode="out-in" name="fade">
                    <template v-if="isQuantitativeQuestionSet(currentQuestionSet)">
                        <div class="flex flex-col grow">
                            <ResponseScoreQuestionSet
                                :reviewee-name="reviewee_name"
                                :question-set="currentQuestionSet"
                                :question-set-values="questionSetValues[currentQuestionSet.id]"
                                :clustered-quantitative-sets-count="clusteredQuantitativeSetsCount"
                                :current-quantitative-set-position="currentQuantitativeSetPosition"
                                @next-question="nextQuestionSet"
                                @previous-question="previousQuestionSet"
                                @answer="setQuestionAnswer"
                            />
                        </div>
                    </template>
                    <template v-else>
                        <div class="max-w-xl my-10 mx-auto grow justify-between flex flex-col gap-8">
                            <template v-for="question in currentQuestionSet.questions" :key="question.id">
                                <div class="grow flex flex-col gap-8">
                                    <Transition appear name="fade">
                                        <div>{{ question.text }}</div>
                                    </Transition>
                                    <Transition name="fade">
                                        <div
                                            class="prose bg-white p-4 rounded-xl ml-8 text-valence-grey-600"
                                            v-if="questionSetValues[currentQuestionSet.id][question.id]"
                                            v-text="questionSetValues[currentQuestionSet.id][question.id]"
                                        ></div>
                                    </Transition>
                                    <Transition mode="in-out" name="fade">
                                        <ChatWaitingMessage v-if="showWaitingMessage" />
                                    </Transition>
                                </div>
                                <div class="shrink-0" v-if="question.question_type === 'Text'">
                                    <ResponseTextQuestion @answer="(answer) => handleTextAnswer(question.id, answer)" />
                                </div>
                            </template>
                        </div>
                    </template>
                </Transition>
            </template>
            <template v-else-if="flowStep === FLOW_STEP.SUMMARY">
                <div class="max-w-xl my-10 mx-auto grow justify-between flex flex-col gap-8">
                    <div class="prose grow">
                        <Transition mode="out-in" name="fade">
                            <div v-if="response_summary">
                                Thank you so much for your feedback! {{ reviewee_name }} has made their profile public, and based on what you&apos;ve shared, here&apos;s how you
                                might work best with them:

                                <br /><br />
                                {{ response_summary }}
                                <br /><br />

                                I&apos;m just scratching the surface — the more I get to know you, the smarter and more tailored my insights become.
                            </div>
                            <ChatWaitingMessage v-else />
                        </Transition>
                    </div>
                    <div class="shrink-0 flex justify-center">
                        <button
                            :disabled="response_submitted || !response_summary"
                            class="bg-valence-pink hover:bg-valence-pink-600 transition-colors ease-in-out duration-150 text-white font-bold py-2 px-8 leading-none font-medium rounded-full disabled:bg-transparent disabled:border-valence-pink disabled:border disabled:text-valence-pink"
                            type="button"
                            @click="submitResponse()"
                        >
                            <Transition name="fade" mode="out-in">
                                <span v-if="!response_submitted"> Submit </span>
                                <span v-else>
                                    <i class="bi bi-check w-3 h-3"></i>
                                    Submitted
                                </span>
                            </Transition>
                        </button>
                    </div>
                </div>
            </template>
        </Transition>
    </div>
</template>

<script setup>
import { computed, reactive, ref, onMounted, inject, onUnmounted, nextTick } from "vue";
import { router } from "@inertiajs/vue3";
import { getCookie } from "/js/utils.js";
import ResponseTextQuestion from "~vue/ReviewResponse/ResponseTextQuestion.vue";
import ResponseScoreQuestionSet from "~vue/ReviewResponse/ResponseScoreQuestionSet.vue";
import ChatWaitingMessage from "~vue/ChatWaitingMessage.vue";

const FLOW_STEP = {
    INTRO: "intro",
    QUESTIONS: "questions",
    SUMMARY: "summary",
};

const POST_STEP = {
    RESPONSE: "response",
    SUBMIT: "submit",
};

const FLOW_ORDER = [FLOW_STEP.INTRO, FLOW_STEP.QUESTIONS, FLOW_STEP.SUMMARY];

const props = defineProps({
    review_id: {
        type: String,
        default: () => "",
    },
    reviewee_name: {
        type: String,
        default: () => "",
    },
    response_summary: {
        type: String,
        default: () => null,
    },
    response_submitted: {
        type: Boolean,
        default: () => false,
    },
    response_id: {
        type: String,
        default: () => null,
    },
    question_sets: {
        type: Array,
        default: () => [],
    },
    event_context: {
        type: Object,
        default: () => {},
    },
});

const { $setEventContext } = inject("globalProperties");

const showWaitingMessage = ref(false);
const flowStep = ref(FLOW_ORDER[0]);
const currentQuestionSetId = ref(props.question_sets[0].id);
const questionSetValues = reactive(initializeQuestionSetAnswers());

const currentQuestionSet = computed(() => {
    return props.question_sets.find((qs) => qs.id === currentQuestionSetId.value);
});
const questionSetIds = computed(() => props.question_sets.map((qs) => qs.id));

const isFirstQuestionSet = computed(() => questionSetIds.value[0] === currentQuestionSetId.value);

const isLastQuestionSet = computed(() => currentQuestionSetId.value === questionSetIds.value[questionSetIds.value.length - 1]);

const clusteredQuantitativeSetsCount = computed(() => {
    /*
     * The design of the review demands grouping question sets using criteria
     * in a way that is not afforded by the data model. The data model
     * affords categorizing questions by type (score, text), but not question
     * sets by type (quantitative, qualitative).
     *
     * The design, however, calls for grouping the quantitative sets
     * and showing a progress indicator of which question set you're currently
     * looking at, relative at the  total amount of quantiative question sets.
     * These "quantitative" sets have behavior and UI elements that are not
     * shared by the "qualitative" sets of text-type questions.
     *
     * Rather than shoe-horning the design requirements into the data model,
     * we do the following:
     *
     * We take at the current question set, and look at its adjacent
     * question sets relative to the configured question set order until we
     * run into a question set whose questions are not all score-type.
     * All these matched "quantitative" questions are thus "clustered".
     * This is a grouping criteria that we can use to satisfy the design
     * requirement without imposing further data model changes.
     */

    const currentQuestionSetIndex = props.question_sets.findIndex((qs) => qs.id === currentQuestionSet.value.id);

    if (currentQuestionSetIndex === -1 && isQuantitativeQuestionSet(currentQuestionSet.value)) {
        return 0;
    }

    // Initialized to 1 as the current question set has been determined to be
    // quantitative.
    let quantitativeSets = 1;

    // We look at the question sets that follow the current question set.
    if (currentQuestionSetIndex !== props.question_sets.length - 1) {
        for (let x = currentQuestionSetIndex + 1; x < props.question_sets.length; x++) {
            if (isQuantitativeQuestionSet(props.question_sets[x])) {
                quantitativeSets++;
            } else {
                break;
            }
        }
    }

    // We look at the question sets that precede the current question set.
    if (currentQuestionSetIndex !== 0) {
        for (let x = currentQuestionSetIndex - 1; x > -1; x--) {
            if (isQuantitativeQuestionSet(props.question_sets[x])) {
                quantitativeSets++;
            } else {
                break;
            }
        }
    }

    return quantitativeSets;
});

const currentQuantitativeSetPosition = computed(() => {
    if (!isQuantitativeQuestionSet(currentQuestionSet.value)) {
        return 0;
    }

    const quantitativeQuestionSets = props.question_sets.filter((qs) => isQuantitativeQuestionSet(qs)).map((qs) => qs.id);

    return quantitativeQuestionSets.indexOf(currentQuestionSet.value.id) + 1;
});

onMounted(() => {
    $setEventContext(props.event_context);
});

onUnmounted(() => {
    $setEventContext({});
});

function isQuantitativeQuestionSet(questionSet) {
    return questionSet.questions.every((q) => q.question_type === "Score");
}

function hasNextFlowStep() {
    return FLOW_ORDER.indexOf(flowStep.value) !== FLOW_ORDER.length - 1;
}

function nextFlowStep() {
    if (hasNextFlowStep()) {
        flowStep.value = FLOW_ORDER[FLOW_ORDER.indexOf(flowStep.value) + 1];
    }
}

function previousQuestionSet() {
    if (isFirstQuestionSet.value) {
        return;
    }

    currentQuestionSetId.value = questionSetIds.value[questionSetIds.value.indexOf(currentQuestionSetId.value) - 1];
}

function postResponse(payload, propsToHydrate = []) {
    return router.post(`/reviews/${props.review_id}/response`, payload, {
        only: propsToHydrate,
        headers: {
            "X-CSRFToken": getCookie("csrftoken"),
        },
    });
}

function createResponse() {
    return postResponse(
        {
            answers: questionSetValues,
            step: POST_STEP.RESPONSE,
        },
        ["response_summary", "response_id"],
    );
}

function submitResponse() {
    return postResponse(
        {
            step: POST_STEP.SUBMIT,
            response_id: props.response_id,
        },
        ["response_submitted"],
    );
}

async function nextQuestionSet() {
    if (isLastQuestionSet.value) {
        await createResponse();
        nextFlowStep();
        return;
    }

    currentQuestionSetId.value = questionSetIds.value[questionSetIds.value.indexOf(currentQuestionSetId.value) + 1];
}

function setQuestionAnswer(questionId, answer) {
    questionSetValues[currentQuestionSetId.value][questionId] = answer;
}

function initializeQuestionSetAnswers() {
    /* Generates a map in the shape of
     * { [questionSetId]: { [questionId]: undefined } }
     * with all the question sets and their questions.
     */
    return props.question_sets.reduce((questionSetMap, questionSet) => {
        return {
            ...questionSetMap,
            [questionSet.id]: questionSet.questions.reduce((questionMap, question) => {
                return {
                    ...questionMap,
                    [question.id]: undefined,
                };
            }, {}),
        };
    }, {});
}

function handleTextAnswer(questionId, answer) {
    /*
     * We're mimicking the async nature of a real chat experience, so we add
     * staggered delays between the loading indicators and continuing.
     * We use `nextTick` to queue the timeouts after Vue flushes each state change.
     */
    setQuestionAnswer(questionId, answer);
    nextTick(() => {
        setTimeout(() => {
            // Wait a 1 second before the chat waiting indicator appears
            showWaitingMessage.value = true;
            nextTick(() => {
                // Wait two seconds before the chat waiting indicator disappears
                setTimeout(() => {
                    showWaitingMessage.value = false;
                    nextTick(() => {
                        // Wait half a second before continuing
                        setTimeout(nextQuestionSet, 500);
                    });
                }, 2000);
            });
        }, 1000);
    });
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 200 ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
