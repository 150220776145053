<template>
    <div class="grow flex flex-col justify-center gap-10">
        <p class="max-w-xl mx-auto text-lg">Let&apos;s start with some quick questions. Use the scale to rate how well {{ revieweeName }} is doing.</p>
        <div class="stacked-grid">
            <div class="z-20 stacked-grid-item bg-white border border-gray-100/80 shadow-black/5 shadow-lg p-6 rounded-xl flex flex-col gap-6 max-w-xl mx-auto">
                <h2 class="text-sm tracking-widest uppercase text-valence-grey-400 font-bold">{{ questionSet.title }}</h2>
                <form class="flex flex-col gap-2">
                    <template v-for="(question, idx) in questionSet.questions" :key="question.id">
                        <div class="grid grid-cols-12 grid-rows-1 items-baseline">
                            <div class="col-span-8">
                                {{ question.text }}
                            </div>
                            <div class="col-span-1"></div>
                            <div class="col-span-3 flex flex-col gap-4 ml-4" v-if="question.question_type === 'Score'">
                                <div class="ml-auto mr-0 flex flex-row items-center justify-between w-full">
                                    <template v-for="val in scoreAnswers" :key="val">
                                        <label
                                            :title="val"
                                            :id="scoreInputKey(question.id, val)"
                                            :ref="(el) => refForLine(el, question.id, val)"
                                            :tabindex="isStarFocusable(question.id, val) ? 0 : '-1'"
                                            class="relative"
                                            :class="isScoreInclusive(question.id, val) ? 'text-valence-pink bi-star-fill' : 'text-valence-grey-400 hover:text-valence-pink bi-star'"
                                            :for="`q-${question.id}-${val}`"
                                        >
                                            <input
                                                tabindex="-1"
                                                :id="`q-${question.id}-${val}`"
                                                v-model="questionSetValues[question.id]"
                                                type="radio"
                                                :name="question.id"
                                                :value="val"
                                                class="absolute opacity-0 w-full h-full"
                                            />
                                            <i class="bi w-8 h-8"></i>
                                        </label>
                                    </template>
                                </div>
                                <div class="text-xs text-valence-grey-600 flex items-center justify-between" v-if="idx === questionSet.questions.length - 1">
                                    <div>Could improve</div>
                                    <div class="text-right">Does well</div>
                                </div>
                            </div>
                        </div>
                    </template>
                </form>
            </div>
            <!-- Adds empty question set container to simulate a tilted & stacked cards effect if adjacent question sets remain -->
            <div
                v-if="clusteredQuantitativeSetsCount - currentQuantitativeSetPosition > 0"
                class="rotate-[-4deg] w-full h-full stacked-grid-item bg-white border border-gray-100/80 p-6 rounded-xl flex flex-col gap-6 max-w-xl mx-auto shadow-black/5 shadow-md"
            ></div>
        </div>
        <div class="w-full flex flex-row justify-center gap-4 items-center">
            <template v-for="qs in clusteredQuantitativeSetsCount">
                <div class="h-1 w-4 rounded-full" :class="qs <= currentQuantitativeSetPosition ? 'bg-valence-pink' : 'bg-valence-grey-400'"></div>
            </template>
        </div>
    </div>
    <div class="shrink-0 flex flex-row justify-between items-center">
        <button
            :disabled="currentQuantitativeSetPosition === 1"
            class="bg-valence-pink hover:bg-valence-pink-600 transition-colors ease-in-out duration-150 text-white font-bold py-2 px-8 leading-none font-medium rounded-full disabled:bg-transparent disabled:border-valence-pink disabled:border disabled:text-valence-pink"
            type="button"
            @click="emit('previousQuestion')"
        >
            Previous
        </button>
        <button
            :disabled="!isCurrentQuestionSetComplete"
            class="bg-valence-pink hover:bg-valence-pink-600 transition-colors ease-in-out duration-150 text-white font-bold py-2 px-8 leading-none font-medium rounded-full disabled:bg-transparent disabled:border-valence-pink disabled:border disabled:text-valence-pink"
            type="button"
            @click="emit('nextQuestion')"
        >
            Next
        </button>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";

const props = defineProps({
    revieweeName: String,
    questionSet: Object,
    questionSetValues: Object,
    clusteredQuantitativeSetsCount: Number,
    currentQuantitativeSetPosition: Number,
});

const emit = defineEmits(["nextQuestion", "previousQuestion", "answer"]);

const scoreAnswers = [1, 2, 3, 4, 5];
const scoreInputRefs = ref(new Map());

onMounted(() => {
    document.addEventListener("keydown", handleKeydown);
});

onUnmounted(() => {
    document.removeEventListener("keydown", handleKeydown);
});

function handleKeydown(event) {
    /*
	 * The design of the score-based question demands a radio input that uses
	 * a custom control. Because of this, we have to reimplement the default
	 * keyboard navigation that the browser's native control affords: using tab
	 * to move between radio inputs, using arrow keys to move and select within
	 * a single input's values, and using space bar to select the focused input.
	 *
	 * Here we listen for these keydown events, and match the would-be focused
	 * element using an encoded key in the `id` attribute.
	 * This encoded key is a string containing the question id and the
	 * score value.

	 * For example, for given question with question id of 1, there will
	 * be 5 inputs, with DOM ids like `1-1`, `1-2`, `1-3`, etc.
	 *
	 * We decode said question id and score from the DOM id, set the
	 * score value, and focus  the DOM element if the pressed key was
	 * an arrow key.
	 */

    const supportedKeys = ["ArrowLeft", "ArrowRight", "Space"];

    if (supportedKeys.indexOf(event.code) === -1 || !document.activeElement.hasAttribute("id")) {
        return;
    }

    const elementId = document.activeElement.getAttribute("id");

    if (scoreInputRefs.value.has(elementId)) {
        const ref = scoreInputRefs.value.get(elementId);

        if (!ref) {
            return;
        }

        const [questionId, scoreStr] = elementId.split("-");
        const score = Number(scoreStr);

        let answer;

        if (event.code === "Space") {
            answer = score;
        } else if (event.code === "ArrowRight") {
            if (score === 5) {
                answer = 1;
            } else {
                answer = score + 1;
            }
        } else if (event.code === "ArrowLeft") {
            if (score === 1) {
                answer = 5;
            } else {
                answer = score - 1;
            }
        }

        emit("answer", questionId, answer);

        if (!event.code !== "Space") {
            const selectedScore = scoreInputRefs.value.get(scoreInputKey(questionId, answer));
            selectedScore.focus();
        }
    }
}

const isCurrentQuestionSetComplete = computed(() => {
    return Object.values(props.questionSetValues).every(Boolean);
});

function scoreInputKey(questionId, score) {
    return [questionId, score].join("-");
}

function isScoreInclusive(questionId, score) {
    // Is the passed score option equal to or lower than the current question score?
    return props.questionSetValues[questionId] >= score;
}

function isStarFocusable(questionId, score) {
    /*
     * When tabbing radio inputs, the radio option that is "focusable" is the
     * first when no input value is set, otherwise it's the input value option.
     */
    const current = props.questionSetValues[questionId];

    if (!current) {
        return score === 1;
    }

    return current === score;
}

function refForLine(element, questionId, score) {
    if (element) {
        scoreInputRefs.value.set(scoreInputKey(questionId, score), element);
    }
}
</script>

<style>
.stacked-grid {
    display: grid;
    place-items: center;
    grid-template-areas: "stacked";
}

.stacked-grid-item {
    grid-area: stacked;
}
</style>
